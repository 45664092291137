<template>
    <v-row no-gutters class="store-search">
        <v-col cols="5" sm="3" md="3" lg="3">
            <v-autocomplete
                    ref="airportAutocomplete"
                    v-model="airportValue"
                    :items="airportOptions"
                    class="store-search__airport-selector"
                    :loading="isLoading"
                    append-icon="mdi-map-marker"
                    clearable
                    hide-details
                    dense
                    outlined
            />
        </v-col>
        <v-col cols="7" sm="9" md="9" lg="9">
            <v-autocomplete
                    ref="waypointAutocomplete"
                    v-model="waypointValue"
                    :items="locationOptions"
                    class="store-search__waypoint-selector"
                    :loading="isLoading"
                    append-icon="mdi-magnify"
                    clearable
                    hide-details
                    dense
                    outlined
                />
            </v-col>
    </v-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "StoreSearchNew",
    props: ["airport", "waypoint"],
    async mounted() {
        this.isLoading = true;
        await this.getAirports();
        this.isLoading = false;
        this.setAirportMatchingTheLocation();
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters('AirportStore', [
            'airports',
            'locations'
        ]),
        airportOptions() {
            return this.airports.map(airport => ({
                text: `${airport.airportIdent}  -  ${airport.airportName}`,
                value: airport.airportIdent,
            }));
        },
        locationOptions() {
            const locations = this.airport
                ? this.locations.filter(x => x.airportIdent === this.airport)
                : this.locations;

            return locations
                .map(x => ({
                    text: x.terminal
                        ? `${x.name} - ${x.terminal}, ${x.airportIdent}`
                        : `${x.name}, ${x.airportIdent}`,
                    value: x.waypointID,
                }));
        },
        airportValue: {
            get() {
                return this.airport;
            },
            set(value) {
                this.$emit('airport-change', value);
            }
        },
        waypointValue: {
            get() {
                return this.waypoint;
            },
            set(value) {
                this.$emit('waypoint-change', value)
            }
        }
    },
    methods: {
        ...mapActions('AirportStore', [
            'getAirports'
        ]),
        setAirportMatchingTheLocation() {
            if (!this.waypoint) {
                this.$emit('airport-change', null);
            } else {
                const location = this.locations.find(x => x.waypointID === this.waypoint);
                if (location) {
                    this.$emit('airport-change', location.airportIdent);
                }
            }
        }
    },
    watch: {
        waypoint() {
            this.setAirportMatchingTheLocation();
        },
        locations() {
            this.setAirportMatchingTheLocation();
        },
        airportValue() {
            this.$refs.airportAutocomplete.blur();
        },
        waypointValue() {
            this.$refs.waypointAutocomplete.blur();
        }
    }
}
</script>

<style lang="scss">
  .v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: none;
  }
    .store-search {
        &__airport-selector {
            min-width: 120px;
            background-color: #f9f9f9;
            border-radius: 20px 0 0 20px;
        }

        &__waypoint-selector {
            border-radius: 0 20px 20px 0;
        }

        &__waypoint-selector, &__airport-selector {
            font-size:14px;
            .v-input__slot {
                min-height: 30px !important;
            }
            .v-input__append-inner {
                margin-top: 4px !important;
            }
        }
    }
</style>
