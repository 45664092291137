export default {
    computed: {
        waypointId: {
            get() {
                return this.$route.query.waypointId
                    ? parseInt(this.$route.query.waypointId, 10)
                    : null;
            },
            set(value) {
                this.$router.replace({
                    name: this.$route.name, query: {
                        ...this.$route.query,
                        waypointId: value
                    }
                });
            }
        },
    }
}