<template>
    <div class="fill-height" style="background-color: #f9f9f9;">
        <ContextTitle :passed-actions="[]" title="Receipt Templates"/>
        <v-card width="100%" class="pt-3 pb-3" elevation="0" style="border-bottom: 1px solid #e4e4e4">
            <div class="receipts-selector__container pl-9 pr-9">
                <div class="receipts-selector__title">Select a waypoint to start</div>
                <v-row class="mt-3 receipts-selector__waypoint-selector">
                    <v-col md="8" sm="12" class="pt-0 pb-0">
                        <store-search-new
                            :airport="airport"
                            :waypoint="waypointId"
                            @airport-change="airport = $event"
                            @waypoint-change="waypointId = $event"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="receipts-selector__filters pt-0 mt-0">
                    <v-col class="pr-3 pl-1">
                        <v-select
                            v-model="selectedPlatformIds"
                            :items="platformTypeOptions"
                            :loading="isMetadataLoading"
                            multiple
                            attach
                            @change="resetSelection"
                            label="Platforms"
                        />
                    </v-col>
                    <v-col class="pl-3 pr-3">
                        <v-select
                            v-model="selectedOrderStatuses"
                            :items="orderStatusOptions"
                            :loading="isMetadataLoading"
                            multiple
                            attach
                            @change="resetSelection"
                            label="Events"
                        />
                    </v-col>
                    <v-col class="pl-3 pr-1">
                        <v-select
                            v-model="selectedOrderTypes"
                            :items="orderTypeOptions"
                            :loading="isMetadataLoading"
                            multiple
                            attach
                            @change="resetSelection"
                            label="Order types"
                        />
                    </v-col>
                </v-row>
            </div>
        </v-card>

        <div class="receipts-header" v-if="waypointId">
            <div class="receipts-header__title pl-9 pr-9 pt-5 pb-5">
                {{ waypointTitle }}
            </div>
            <div class="messages-header__actions pl-9 pr-9 pb-2 d-flex align-center">
                <div class="messages-header__subtitle pb-1">Receipts</div>
                <v-spacer></v-spacer>
                <template v-if="selectedTemplatesCount > 0">
                    <v-btn
                        @click="bulkEditSelected()"
                        color="#93BD20"
                        text
                    >Edit {{selectedTemplatesCount}} templates <v-icon x-small class="pl-1">edit</v-icon>
                    </v-btn>
                    <v-divider vertical class="mt-3 mb-3"/>
                </template>
                <v-btn
                    v-if="selectedTemplatesCount === 0"
                    @click="selectAll()"
                    color="#93BD20"
                    text
                >
                    Select All
                </v-btn>
                <v-btn
                    v-else
                    @click="resetSelection()"
                    color="#93BD20"
                    text
                >
                    Select none
                </v-btn>
            </div>
        </div>

        <v-row no-gutters class="receipts-container pl-9 pr-9">
            <v-col class="fill-height">
                <v-row class="overflow-y-auto">
                    <h5 v-if="!waypointId" class="pt-3 text-center">Select a waypoint to start</h5>
                    <div v-else-if="isLoading" style="width: 100%">
                        <v-skeleton-loader
                            v-for="i in 8"
                            :key="i"
                            type="article, actions"
                            class="ma-2 d-flex flex-column"
                            elevation="2"
                            width="100%"
                        />
                    </div>
                    <h5 v-else-if="filteredReceipts.length === 0" class="pt-3">
                        Sorry, no receipt templates matches your query.
                    </h5>
                    <template-card-wrapper
                        v-for="receipt in filteredReceipts"
                        :id="receipt.templateID"
                        :selected="!!selectedTemplateIds[receipt.templateID]"
                        :waypointId="waypointId"
                        show-actions
                        @edit="edit"
                        @selected-change="onSelectedChange"
                        :platform-types="platformTypes"
                        :order-types="orderTypes"
                    />
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import waypointIdFromQuery from "mixins/waypointIdFromQuery";
import ContextTitle from "components/context_title";
import StoreSearchNew from "components/store_search_new";
import {mapActions, mapGetters} from "vuex";
import {OperationStatus} from "helpers/common";
import TemplateCardWrapper from "components/receipts/TemplateCardWrapper";
import metadata from "mixins/receiptsMetadata";
import {convertObjectToSelectOption, getArrayFromQueryParam} from "helpers";
import _ from "lodash";
import Vue from "vue";
export default {
    name: "Receipts",
    mixins: [waypointIdFromQuery, metadata],
    components: {
        TemplateCardWrapper,
        ContextTitle,
        StoreSearchNew,
    },
    data: () => ({
        airport: null,
        selectedTemplateIds: {},
        restoreConfirmation: null,
    }),
    mounted() {
        this.fetchReceiptTemplates(this.waypointId);
    },
    computed: {
        ...mapGetters("ReceiptsStore", [
            "receiptsStatus",
            "receiptTemplates",
            "templateMetadataStatus",
            "platformTypes",
            "orderStatuses",
            "orderTypes",
        ]),
        ...mapGetters("AirportStore", ["airports", "locations"]),
        waypointTitle() {
            const location = this.locations.find(x => x.waypointID === this.waypointId);
            return location
                ? location.terminal
                    ? `${location.name} - ${location.terminal}, ${location.airportIdent}`
                    : `${location.name}, ${location.airportIdent}`
                : null;
        },
        isLoading() {
            return this.receiptsStatus === OperationStatus.Unknown
                || this.receiptsStatus === OperationStatus.InProgress;
        },
        isMetadataLoading() {
            return this.templateMetadataStatus === OperationStatus.InProgress;
        },
        platformTypeOptions() {
            return convertObjectToSelectOption(this.platformTypes);
        },
        orderTypeOptions() {
            return convertObjectToSelectOption(this.orderTypes);
        },
        orderStatusOptions() {
            return convertObjectToSelectOption(this.orderStatuses);
        },
        selectedPlatformIds: {
            get() {
                return getArrayFromQueryParam(this.$route.query.platformIds);
            },
            set(value) {
                this.$router.replace({
                    name: "receipts", query: {
                        ...this.$route.query,
                        platformIds: value
                    }
                });
            }
        },
        selectedOrderStatuses: {
            get() {
                return getArrayFromQueryParam(this.$route.query.events);
            },
            set(value) {
                this.$router.replace({
                    name: "receipts", query: {
                        ...this.$route.query,
                        events: value
                    }
                });
            }
        },
        selectedOrderTypes: {
            get() {
                return getArrayFromQueryParam(this.$route.query.orderTypes);
            },
            set(value) {
                this.$router.replace({
                    name: "receipts", query: {
                        ...this.$route.query,
                        orderTypes: value
                    }
                });
            }
        },
        filteredReceipts() {
            return this.receiptTemplates.filter(x => (this.selectedPlatformIds.length === 0 || _.includes(this.selectedPlatformIds, x.platformType.toString()))
                && (this.selectedOrderStatuses.length === 0 || x.orderStatus === 0 || _.includes(this.selectedOrderStatuses, x.orderStatus.toString()))
                && (this.selectedOrderTypes.length === 0 || x.orderType === 0 || _.includes(this.selectedOrderTypes, x.orderType.toString())
                ));
        },
        selectedTemplatesCount() {
            return Object.values(this.selectedTemplateIds).filter(x => x).length;
        },
    },
    methods: {
        ...mapActions("ReceiptsStore", ["fetchReceiptTemplates", "fetchReceiptTemplateMetadata"]),
        edit({id}) {
            this.$router.push({
                name: 'receipts-edit',
                params: {
                    waypointID: this.waypointId,
                    templateID: id,
                },
            });
        },
        resetSelection() {
            this.selectedTemplateIds = {};
        },
        selectAll() {
            for (const templateId of this.filteredReceipts.map(x => x.templateID)) {
                Vue.set(this.selectedTemplateIds, templateId, true)
            }
        },
        onSelectedChange({templateID, selected}) {
            Vue.set(this.selectedTemplateIds, templateID, selected);
        },
        bulkEdit(ids) {
            this.$router.push({
                name: `receipts-bulk-edit`,
                params: {

                    waypointID: this.waypointId,
                },
                query: {
                    templateIds: ids ? ids : this.receiptTemplates.map(x => x.templateID),
                }
            });
        },
        bulkEditSelected() {
            this.bulkEdit(Object.entries(this.selectedTemplateIds)
                .filter(([_,v]) => v)
                .map(([k,_]) => k));
        }
    },
    watch: {
        waypointId(value) {
            if (value) {
                this.fetchReceiptTemplates(value);
            } else {
                this.fetchReceiptTemplates();
            }
        },
    }
}
</script>

<style scoped lang="scss">
    @import "../../scss/variables";
    @import "../../scss/mixins";
    $internalContentMaxWidth: 800px;
    .receipts-selector {
        &__title {
             font-weight: bold;
             color: $grey_dark_1;
             font-size: fz(md);
        }
        &__container {
             max-width: $internalContentMaxWidth;
        }
    }
    .receipts-header {
        max-width: $internalContentMaxWidth;

        &__title {
            font-weight: bold;
            font-size: fz(venti);
            color: $grey_dark_1;
        }
        &__subtitle{
            font-weight: bold;
            color: $grey_dark_1;
            font-size: fz(md);
        }
        &__reset-confirmation {
            font-size: fz(sm);
        }
    }
    .receipts-container {
        max-width: $internalContentMaxWidth;
    }
</style>
