var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "fill-height",
      staticStyle: { "background-color": "#f9f9f9" },
    },
    [
      _c("ContextTitle", {
        attrs: { "passed-actions": [], title: "Receipt Templates" },
      }),
      _c(
        "v-card",
        {
          staticClass: "pt-3 pb-3",
          staticStyle: { "border-bottom": "1px solid #e4e4e4" },
          attrs: { width: "100%", elevation: "0" },
        },
        [
          _c(
            "div",
            { staticClass: "receipts-selector__container pl-9 pr-9" },
            [
              _c("div", { staticClass: "receipts-selector__title" }, [
                _vm._v("Select a waypoint to start"),
              ]),
              _c(
                "v-row",
                { staticClass: "mt-3 receipts-selector__waypoint-selector" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0 pb-0", attrs: { md: "8", sm: "12" } },
                    [
                      _c("store-search-new", {
                        attrs: {
                          airport: _vm.airport,
                          waypoint: _vm.waypointId,
                        },
                        on: {
                          "airport-change": function ($event) {
                            _vm.airport = $event
                          },
                          "waypoint-change": function ($event) {
                            _vm.waypointId = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "receipts-selector__filters pt-0 mt-0",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-3 pl-1" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.platformTypeOptions,
                          loading: _vm.isMetadataLoading,
                          multiple: "",
                          attach: "",
                          label: "Platforms",
                        },
                        on: { change: _vm.resetSelection },
                        model: {
                          value: _vm.selectedPlatformIds,
                          callback: function ($$v) {
                            _vm.selectedPlatformIds = $$v
                          },
                          expression: "selectedPlatformIds",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-3 pr-3" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.orderStatusOptions,
                          loading: _vm.isMetadataLoading,
                          multiple: "",
                          attach: "",
                          label: "Events",
                        },
                        on: { change: _vm.resetSelection },
                        model: {
                          value: _vm.selectedOrderStatuses,
                          callback: function ($$v) {
                            _vm.selectedOrderStatuses = $$v
                          },
                          expression: "selectedOrderStatuses",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-3 pr-1" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.orderTypeOptions,
                          loading: _vm.isMetadataLoading,
                          multiple: "",
                          attach: "",
                          label: "Order types",
                        },
                        on: { change: _vm.resetSelection },
                        model: {
                          value: _vm.selectedOrderTypes,
                          callback: function ($$v) {
                            _vm.selectedOrderTypes = $$v
                          },
                          expression: "selectedOrderTypes",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.waypointId
        ? _c("div", { staticClass: "receipts-header" }, [
            _c(
              "div",
              { staticClass: "receipts-header__title pl-9 pr-9 pt-5 pb-5" },
              [_vm._v(" " + _vm._s(_vm.waypointTitle) + " ")]
            ),
            _c(
              "div",
              {
                staticClass:
                  "messages-header__actions pl-9 pr-9 pb-2 d-flex align-center",
              },
              [
                _c("div", { staticClass: "messages-header__subtitle pb-1" }, [
                  _vm._v("Receipts"),
                ]),
                _c("v-spacer"),
                _vm.selectedTemplatesCount > 0
                  ? [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "#93BD20", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.bulkEditSelected()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "Edit " +
                              _vm._s(_vm.selectedTemplatesCount) +
                              " templates "
                          ),
                          _c(
                            "v-icon",
                            { staticClass: "pl-1", attrs: { "x-small": "" } },
                            [_vm._v("edit")]
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticClass: "mt-3 mb-3",
                        attrs: { vertical: "" },
                      }),
                    ]
                  : _vm._e(),
                _vm.selectedTemplatesCount === 0
                  ? _c(
                      "v-btn",
                      {
                        attrs: { color: "#93BD20", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.selectAll()
                          },
                        },
                      },
                      [_vm._v(" Select All ")]
                    )
                  : _c(
                      "v-btn",
                      {
                        attrs: { color: "#93BD20", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.resetSelection()
                          },
                        },
                      },
                      [_vm._v(" Select none ")]
                    ),
              ],
              2
            ),
          ])
        : _vm._e(),
      _c(
        "v-row",
        {
          staticClass: "receipts-container pl-9 pr-9",
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            { staticClass: "fill-height" },
            [
              _c(
                "v-row",
                { staticClass: "overflow-y-auto" },
                [
                  !_vm.waypointId
                    ? _c("h5", { staticClass: "pt-3 text-center" }, [
                        _vm._v("Select a waypoint to start"),
                      ])
                    : _vm.isLoading
                    ? _c(
                        "div",
                        { staticStyle: { width: "100%" } },
                        _vm._l(8, function (i) {
                          return _c("v-skeleton-loader", {
                            key: i,
                            staticClass: "ma-2 d-flex flex-column",
                            attrs: {
                              type: "article, actions",
                              elevation: "2",
                              width: "100%",
                            },
                          })
                        }),
                        1
                      )
                    : _vm.filteredReceipts.length === 0
                    ? _c("h5", { staticClass: "pt-3" }, [
                        _vm._v(
                          " Sorry, no receipt templates matches your query. "
                        ),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.filteredReceipts, function (receipt) {
                    return _c("template-card-wrapper", {
                      attrs: {
                        id: receipt.templateID,
                        selected: !!_vm.selectedTemplateIds[receipt.templateID],
                        waypointId: _vm.waypointId,
                        "show-actions": "",
                        "platform-types": _vm.platformTypes,
                        "order-types": _vm.orderTypes,
                      },
                      on: {
                        edit: _vm.edit,
                        "selected-change": _vm.onSelectedChange,
                      },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }